import { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  const token = null;

  return token ? <Outlet /> : <Navigate to="/link-in" />;
};

export default PrivateRoutes;
