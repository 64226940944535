import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import Page from "../../Page/Page";
import useFetchAllTgGroups from "./useFetchAllTgGroups";

export default function TgGroupsPage() {
  const [tgGroups, setTgGroups] = useState([]);
  const { fetchAllTgGroups } = useFetchAllTgGroups();
  useEffect(() => {
    fetchAllTgGroups().then(setTgGroups);
  }, [fetchAllTgGroups]);
  useEffect(() => {
    console.log(tgGroups);
  }, [tgGroups]);
  return (
    <Page>
      {" "}
      <div
        id="main-content-container-1280"
        className="w-full max-w-5xl flex flex-col mx-auto pt-[3px]"
      >
        <div
          id="top-bar"
          className="w-full outline p-4 flex items-center justify-between bg-gray-900 rounded-xl"
        >
          <h2 className="text-xl text-white font-semibold">
            All Telegram Groups:
          </h2>
          <div
            onClick={null}
            className="w-8 h-8 bg-gray-100 rounded-md flex items-center justify-center cursor-pointer"
          >
            <FontAwesomeIcon
              icon={faArrowRotateRight}
              className="w-[75%] h-[75%] pr-[1px]"
            />
          </div>
        </div>
        <div id="groups-wrapper" className="flex flex-wrap">
          {tgGroups.length > 0 &&
            tgGroups.map((group, index) => (
              <div className="w-[50%] p-2">
                <div className="border border-2 border-gray-900 overflow-hidden p-2 bg-gray-100 rounded-xl">
                  <pre key={index} className="">
                    {JSON.stringify(group, null, 2)}
                  </pre>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Page>
  );
}
