import React from "react";
import { ReactComponent as AngledBracket } from "./angled-bracket.svg";
import { Link } from "react-router-dom";

export default function NewItem() {
  return (
    <div
      id="item-wrapper"
      className="h-[280px] w-[200px] inline-block  pr-3 lg:pr-4"
    >
      <div
        id="item-back"
        className="w-full h-full p-0 pb-0 rounded-t-lg overflow-hidden 
        "
        // bg-gradient-to-b from-indigo-300 to-transparent
      >
        {" "}
        <div
          id="full-item"
          className="relative h-full rounded-t-lg rounded-b-lg overflow-hidden flex flex-col"
        >
          {/* <div className="absolute flex w-full h-full bottom-0 right-0">
            <div className="text-right w-full mt-auto pt-4 pr-1.5 bg-gradient-to-b from-transparent to-white">
            </div>
          </div> */}
          <div
            id="item-image"
            className="flex h-[130px] cursor-grab rounded-t-lg overflow-hidden w-full items-center justify-center  bg-gray-400"
          >
            {true ? (
              <AngledBracket className="w-full h-full" />
            ) : (
              <img
                src="https://source.unsplash.com/random/800x600?nature"
                alt=" "
                className=""
              />
            )}
          </div>
          <div
            id="item-content-wrapper"
            className="absolute left-0 top-0 w-full h-full overflow-scroll"
          >
            <div
              id="item-content"
              className="relative w-full bg-white  mt-[130px] "
            >
              <div
                id="item-content-edge-abs"
                className="h-[8px] w-full left-0 top-0 bg-white -translate-y-full rounded-t-md"
              />
              <div className="w-full h-hull px-2">
                <div id="middle-bar" className="mb-[2.5px]">
                  <span className="text-indigo-600 font-medium block text-xs">
                    {/* {formattedDate} */}
                    May 14 20:24
                  </span>
                  <span className="text-gray-400 font-medium block text-xs">
                    Downtown LA
                  </span>
                </div>
                <Link
                  to={"/post"}
                  className="cursor-pointer text-sm text-gray-900 font-bold leading-tight   line-clamp-2 whitespace-normal mb-[2.5px] hover:underline"
                >
                  {/* {item.first_sentence} */}
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Dolorem molestiae doloribus libero neque rerum natus
                  consequuntur impedit, incidunt delectus voluptates aspernatur
                  voluptatum eaque distinctio veniam consequatur nostrum optio
                  officiis ullam enim molestias reiciendis maxime aperiam?
                  Obcaecati quam expedita hic vero a corrupti nemo harum aliquid
                  doloribus deleniti
                </Link>
                <p className="text-xs text-gray-500 leading-tight whitespace-normal pb-2">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Dolorem molestiae doloribus libero neque rerum natus
                  consequuntur impedit, incidunt delectus voluptates aspernatur
                  voluptatum eaque distinctio veniam consequatur nostrum optio
                  officiis ullam enim molestias reiciendis maxime aperiam?
                  Obcaecati quam expedita hic vero a corrupti nemo harum aliquid
                  doloribus deleniti
                  {/* {item.remaining_text} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
