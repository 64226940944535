import React, { useEffect } from "react";
import { usePostsQuery } from "./fetches/usePostQuery";

export default function AllPostsTab() {
  const { data, isLoading, error } = usePostsQuery();
  useEffect(() => {
    console.log(data);
  }, [data]);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">
              Post Name
            </th>
            <th scope="col" class="px-6 py-3">
              Created At
            </th>
            <th scope="col" class="px-6 py-3">
              Num of Media
            </th>
            <th scope="col" class="px-6 py-3">
              None
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((post, index) => (
            <tr
              key={post._id || index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {post.title}
              </th>
              <td className="px-6 py-4">
                {new Date(post.createdAt).toLocaleString()}
              </td>
              <td className="px-6 py-4">{post.mediaFiles?.length || 0}</td>
              <td className="px-6 py-4">None</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
