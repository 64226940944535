import React, { useState } from "react";
import NewJobTab from "./Tabs/NewJobTab/NewJobTab";
import ActiveJobsTab from "./Tabs/AllJobsTabs/ActiveJobsTab";
import ArchiveJobsTab from "./Tabs/AllJobsTabs/ArchiveJobsTab";

const jobTabs = [
  { id: 0, title: "New Job", component: NewJobTab },
  { id: 1, title: "Active Jobs", component: ActiveJobsTab },
  { id: 2, title: "Archive", component: ArchiveJobsTab },
];

export default function JobsManager() {
  const [activeJobTab, setActiveJobTab] = useState(0);
  const handleFirstTabClick = (index) => {
    setActiveJobTab(index);
  };

  return (
    <div id="job-manager">
      <div
        id="tabs-line"
        className="w-full flex justify-between border-b border-gray-600"
      >
        {jobTabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => handleFirstTabClick(index)}
            className={`flex items-center justify-center rounded-t-lg h-14 px-4 text-sm font-medium first:ml-0 min-w-max border-b-2 border-transparent text-gray-500 hover:border-indigo-300 dark:text-gray-400 cursor-pointer ${
              activeJobTab === index
                ? "border-b-2 border-indigo-600 text-indigo-600 dark:border-indigo-500 dark:text-indigo-400"
                : ""
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {jobTabs.map((tab, index) =>
        index === activeJobTab ? <tab.component key={index} /> : null
      )}
    </div>
  );
}
