import React, { useContext } from "react";
import { ServerErrorContext } from "../../providers/http/ServerErrorProvider";
import { ReactComponent as CloseButton } from "../0/close.svg";

function NotificationPopup() {
  const { serverError, removeError } = useContext(ServerErrorContext);
  return (
    <div className="fixed w-[400px] right-2 sm:right-4 bottom-[20px] z-50 flex flex-col justify-end overflow-visible">
      <div>
        {serverError.map((error) => (
          <div className="p-[10px] pt-0 relative">
            <h3
              className="absolute -translate-y-[100%] right-4 cursor-pointer"
              onClick={() => removeError(error)}
            >
              X
            </h3>
            <div className="p-[10px] border-l border-l-4 border-l-blue-800  backdrop-blur-sm bg-black bg-opacity-70">
              <h3 className="font-medium text-blue-400 pl-2 pb-1">
                Message from server:
              </h3>{" "}
              <p className="text-gray-100 text-lg leading-5">{error.message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotificationPopup;
