import React, { useState, useEffect, useRef, forwardRef } from "react";

export default function MediaCarousel() {
  const carouselRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const items = Array.from({ length: 3 }, (item, index) => index + 1);
  const [slides, setSlides] = React.useState(
    items.map((item) => ({
      ...item,
      ref: React.createRef(),
    }))
  );

  return (
    <>
      <div
        ref={carouselRef}
        id="media-carousel"
        className="flex overflow-scroll bg-gray-300 snap-x snap-mandatory"
      >
        {slides.map((item, index) => (
          <MediaSlide
            index={index}
            isLast={index === slides.length - 1}
            ref={item.ref}
          />
        ))}
      </div>
      <div
        id="media-carousel-repl"
        className="relative h-16 flex px-1 my-4 bg-gray-300"
      >
        <div
          id="controller-abs"
          className="absolute border border-4 border-orange-300 h-full aspect-ratio-155 rounded-md mr-3"
        />
        {items.map((_, index) => (
          <MediaRepl index={index} />
        ))}
      </div>
    </>
  );
}

const MediaSlide = forwardRef(({ index, isLast }, ref) => {
  const itemRef = useRef(null);

  const bgKey = 4 + index;
  useEffect(() => {
    if (ref) {
      ref.current = itemRef.current; // Attach the ref to the DOM node or component instance
    }
  }, [ref]);
  return (
    <>
      <div
        key={index}
        ref={itemRef}
        className={`${
          index === 0 ? "ml-4" : ""
        } w-full md:max-w-[700px] shrink-0  md:mr-4 ${
          isLast === true ? "mr-4" : ""
        } snap-start snap-always`}
      >
        <div
          id="media-post"
          className={`md:ml-2 w-full bg-gray-${bgKey}00 h-full aspect-ratio-155 md:rounded-lg`}
        />
      </div>
    </>
  );
});

const MediaRepl = ({ index }) => {
  return (
    <div
      key={index}
      id="media-post-repl"
      className="outline bg-gray-200 h-full aspect-ratio-155 rounded-md mr-3"
    />
  );
};
