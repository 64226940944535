import React, { useCallback } from "react";

export default function useFetchAvailableBots() {
  const fetchAvailableBots = useCallback(() => {
    console.log("http://localhost:2014/all-bots");
    return fetch(`http://localhost:2014/all-bots`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => data.data)
      .catch((error) => {
        console.log(error);
        return [];
      });
  }, []);

  return { fetchAvailableBots };
}
