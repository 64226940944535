export const handlePostRequestSubmit = async (jobFormData, setJobFormData) => {
  // Create a FormData object
  const data = new FormData();

  // Append all the jobFormData fields to the FormData object
  data.append("post_ref", jobFormData.post_ref);
  data.append("start_date", jobFormData.start_date.toISOString());
  data.append("duration", jobFormData.duration);
  data.append("immediate_start", jobFormData.immediate_start);
  data.append("prefered_geo", JSON.stringify(jobFormData.prefered_geo));
  data.append(
    "selected_post",
    jobFormData.selected_post ? jobFormData.selected_post.my_post_id : null
  );
  data.append("selected_period", jobFormData.selected_period);
  data.append("selected_presets", JSON.stringify(jobFormData.selected_presets));

  try {
    console.log(data);
    const response = await fetch("http://localhost:7117/jobs", {
      method: "POST",
      body: data,
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log("Success:", result);

    // Reset form or handle success (e.g., show a success message)
    setJobFormData({
      post_ref: null,
      start_date: new Date(),
      duration: 3,
      immediate_start: false,
      prefered_geo: [],
      selected_post: null,
      selected_period: 3,
      selected_presets: [],
    });

    // You might want to trigger a success notification here
  } catch (error) {
    console.error("Error:", error);
    // Handle errors (e.g., show an error message)
    // You might want to set an error state here to display to the user
  }
};
