import React, { useState } from "react";
import handlePostRequestSubmit from "./fetches/usePostQuery";

export default function AddPostTab() {
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    media: [],
  });

  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: Array.from(files),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create a FormData object to send files
    const data = new FormData();
    data.append("title", formData.title);
    data.append("text", formData.text);
    formData.media.forEach((file, index) => {
      data.append(`media`, file);
    });

    try {
      const response = await fetch("http://localhost:7117/posts", {
        method: "POST",
        body: data,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setFormData({
        title: "",
        text: "",
        media: [],
      });
      const result = await response.json();
      console.log("Success:", result);
      // Handle success (e.g., show a success message, clear the form)
    } catch (error) {
      console.error("Error:", error);
      // Handle errors (e.g., show an error message)
    }
  };

  return (
    <div className="w-full flex flex-col items-center p-4">
      <form onSubmit={handleSubmit}>
        <label
          htmlFor="post_title"
          className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
        >
          Name Your Post:
        </label>
        <input
          type="text"
          id="post_title"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 max-w-[425px]"
          placeholder="My Awesome Post"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          required
        />
        <label
          htmlFor="message"
          className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
        >
          Create Your Post Here:
        </label>
        <textarea
          id="message"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 max-w-[425px]"
          placeholder="Write your post here..."
          name="text"
          value={formData.text}
          onChange={handleInputChange}
          required
        ></textarea>
        <label
          htmlFor="multiple_files"
          className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
        >
          Add Some Media Files:
        </label>
        <input
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 max-w-[425px] focus:ring-indigo-500 focus:border-indigo-500 dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
          id="multiple_files"
          type="file"
          onChange={handleInputChange}
          multiple
          name="media"
        />
        <p
          className="mt-1 text-sm text-gray-500 dark:text-gray-300"
          id="file_input_help"
        >
          SVG, PNG, JPG or GIF (MAX. 800x400px).
        </p>
        <button
          type="submit"
          className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-12 mt-12 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800"
        >
          Submit Post
        </button>
      </form>
    </div>
  );
}
