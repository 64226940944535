import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MobileMenu from "./MobileMenu/MobileMenu";
import LeftPanel from "./LeftPanel/LeftPanel";
import Popup from "./Popup/Popup";
import NotificationPopup from "./NotificationPopup/NotificationPopup";
import AuthFixed from "./AuthFixed/AuthFixed";
import { Flowbite } from "flowbite-react";

export default function Page({ children }) {
  return (
    <Flowbite>
      <div
        id="page"
        className="relative min-h-full h-full w-full overflow-hidden flex flex-col flex-1 bg-black pt-[60px]"
      >
        <Popup />
        <NotificationPopup />
        {/* <LeftPanel /> */}
        <MobileMenu />
        <Header />
        <div id="main-content" className="flex flex-1 h-full min-h-full">
          {children}
        </div>
        <Footer />
      </div>
    </Flowbite>
  );
}
