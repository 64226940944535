import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white h-[77px] mb-0 mt-auto flex">
      <div className="max-w-screen-2xl w-full h-full mx-auto flex justify-center items-center">
        <h1 className="text-2xl font-extrabold min-w-0 pl-4">TELEGRAM4.PRO</h1>
      </div>
    </footer>
  );
};

export default Footer;
