import React from "react";

export default function GroupCheck() {
  return (
    <>
      {" "}
      <div
        id="group-input"
        className="w-full flex flex-col items-center relative mb-16"
      >
        <div className="w-full flex flex-col items-center relative z-10 mb-24">
          <h3 className="text-white text-center mb-16 text-3xl ">
            Check Interested Chat
            <br /> In Our Supergroup Database:
          </h3>
          <div>
            <div className="mb-24 flex items-center">
              <span className="flex items-center relative">
                <p className="text-gray-400 absolute left-5 text-lg font-semibold">
                  @
                </p>
                <input className="border border-4 border-indigo-300 bg-transparent text-xl px-4 py-3 text-white rounded-full mx-auto pl-12 mr-2" />
              </span>
              <button className="bg-gradient-to-b from-indigo-200 to-indigo-300  py-2 px-4 text-3xl rounded-full border border-4 border-indigo-300 text-indigo-900 font-bold">
                +
              </button>
            </div>
          </div>
          <button className="bg-gradient-to-b from-indigo-500/75 to-indigo-700/75 py-3 text-xl rounded-full px-8 border border-2 border-indigo-500 text-indigo-200 font-bold shadow shadow-xl">
            Check Now!
          </button>
        </div>
        <div class="absolute bottom-0 z-[0] w-full md:w-[100%] max-w-[1100px] h-0 border-b-[100px] mdborder-b-[150px] border-b-indigo-500 border-l-[200px] border-l-transparent border-r-[200px] border-r-transparent z-0  blur-xl opacity-[50%]"></div>{" "}
      </div>
    </>
  );
}
