import React from "react";

export default function PostHighlights({ messageDetailsGrouped }) {
  return (
    <div className="flex flex-col w-full bg-gray-800 rounded-t-xl pt-3 pb-5 px-4">
      <h2 className="font-extrabold mb-3 text-white">Post Highlights:</h2>
      <div className="w-full h-full flex px-12">
        {messageDetailsGrouped.map((group, key) => (
          <div key={key} className="flex flex-col h-full w-[50%]">
            {group.map((item, ikey) => (
              <div className="outline outline-1 outline-gray-500 px-2">
                <h3 className="ml-1 mt-1 text-sm font-semibold px-2 bg-sky-800 w-min whitespace-nowrap rounded-full text-white">
                  {item.title}
                </h3>
                <p className="text-white">{item.answer}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
