import React, { useState } from "react";
import AddNewGroupTab from "./Tabs/AddNewGroupTab";
import SearchGroupTab from "./Tabs/SearchGroupTab";
import GroupPresetsTab from "./Tabs/GroupPresetsTab";
import MyGroupsTab from "./Tabs/MyGroupsTab";

const groupTabs = [
  { id: 0, title: "Add New Group", component: AddNewGroupTab },
  { id: 1, title: "Search Groups", component: SearchGroupTab },
  { id: 2, title: "Group Presets", component: GroupPresetsTab },
  { id: 3, title: "My Groups", component: MyGroupsTab },
];

export default function GroupManager() {
  const [activegroupTab, setActivegroupTab] = useState(0);

  const handleFirstTabClick = (index) => {
    setActivegroupTab(index);
  };

  return (
    <div id="group-manager">
      <div
        id="tabs-line"
        className="w-full flex justify-between border-b border-gray-600"
      >
        {groupTabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => handleFirstTabClick(index)}
            className={`flex items-center justify-center rounded-t-lg h-14 px-4 text-sm font-medium first:ml-0 min-w-max border-b-2 border-transparent text-gray-500 hover:border-indigo-300 dark:text-gray-400 cursor-pointer ${
              activegroupTab === index
                ? "border-b-2 border-indigo-600 text-indigo-600 dark:border-indigo-500 dark:text-indigo-400"
                : ""
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {groupTabs.map((tab, index) =>
        index === activegroupTab ? <tab.component key={index} /> : null
      )}
    </div>
  );
}
