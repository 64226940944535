const customTheme = {
  tablist: {
    base: "flex justify-between",
    tabitem: {
      base: "flex items-center justify-center rounded-t-lg h-14 px-4 text-sm font-medium first:ml-0 min-w-max",
      variant: {
        underline: {
          active: {
            on: "active rounded-t-lg border-b-2 border-indigo-600 text-indigo-600 dark:border-indigo-500 dark:text-indigo-400 hover:border-indigo-300",
            off: "border-b-2 border-transparent text-gray-500 hover:border-indigo-300 dark:text-gray-400",
          },
        },
      },
    },
  },
  tabitemcontainer: {
    base: "overflow-x-scroll",
  },
  tabpanel: "py-3 min-h-[500px]", // You can also add min-height to the tab content if needed
};

const customThemeDatepicker = {
  popup: {
    root: {
      base: "absolute top-12 right-0 z-50 block pt-2", // Changed top-10 to top-20
    },
  },
};

const datepickerThemeConfig = {
  root: {
    base: "relative",
  },
  popup: {
    root: {
      base: "absolute top-10 z-50 block pt-2",
      inline: "relative top-0 z-auto",
      inner: "inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700",
    },
    header: {
      base: "",
      title:
        "px-2 py-3 text-center font-semibold text-gray-900 dark:text-white",
      selectors: {
        base: "mb-2 flex justify-between",
        button: {
          base: "rounded-lg bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600",
          prev: "",
          next: "",
          view: "",
        },
      },
    },
    view: {
      base: "p-1",
    },
    footer: {
      base: "mt-2 flex space-x-2",
      button: {
        base: "w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-indigo-300",
        today:
          "bg-indigo-500 text-white hover:bg-indigo-600 dark:bg-indigo-500 dark:hover:bg-indigo-500",
        clear:
          "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500",
      },
    },
  },
  views: {
    days: {
      header: {
        base: "mb-1 grid grid-cols-7",
        title:
          "h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400",
      },
      items: {
        base: "grid w-64 grid-cols-7",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
          selected: "bg-indigo-500 text-white hover:bg-indigo-500",
          disabled: "text-gray-500",
        },
      },
    },
    months: {
      items: {
        base: "grid w-64 grid-cols-4",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
          selected: "bg-indigo-700 text-white hover:bg-indigo-600",
          disabled: "text-gray-500",
        },
      },
    },
    years: {
      items: {
        base: "grid w-64 grid-cols-4",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
          selected: "bg-indigo-700 text-white hover:bg-indigo-600",
          disabled: "text-gray-500",
        },
      },
    },
    decades: {
      items: {
        base: "grid w-64 grid-cols-4",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
          selected: "bg-indigo-700 text-white hover:bg-indigo-600",
          disabled: "text-gray-500",
        },
      },
    },
  },
};

export { customTheme, customThemeDatepicker, datepickerThemeConfig };
