import { useEffect } from "react";

const useEStart = () => {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/e`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ e: "/start", dateTime: Date.now() }),
      credentials: "include", // Include cookies (required for session management)
    })
      .then((response) => {
        if (response.ok) {
          console.log("Event registered successfully");
        } else {
          console.error("Error registering event:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error registering event:", error);
      });
  }, []);
};

export default useEStart;
