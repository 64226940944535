import React, { useEffect } from "react";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as AngleDown } from "./angle-down.svg";

const pricingData = [
  {
    days: 1,
    price: 2.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 2,
    price: 4.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 4,
    price: 9.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 7,
    price: 14.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 14,
    price: 19.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 30,
    price: 14.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 90,
    price: 49.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
  {
    days: 180,
    price: 79.99,
    originalPrice: 29.99,
    features: [
      "24/7 Service Running",
      "Logging Reports",
      "Custom Chats",
      "Personalized Geo",
      "Up to 8 Media Files",
      "Support 24/7",
    ],
    special: true,
  },
];

const PricingCard = ({ item }) => {
  if (item.special) {
    return (
      <div
        className={`h-[500px] w-[300px] border border-2 border-indigo-500/90 bg-gradient-to-b from-indigo-600/15 via-transparent to-indigo-500/35 rounded-2xl mx-2 flex-none flex flex-col items-center p-4 ${
          item.days === 30 && "scroll-snap-align-center"
        } ${item.days === 180 && "mr-[450px]"}`}
      >
        <div className="w-[150px] h-[150px] bg-gradient-to-b from-blue-300 to-indigo-500 rounded-full flex flex-col items-center justify-center snap-center">
          <span className="text-black text-5xl font-bold">{item.days}</span>
          <span className="text-black text-5xl font-bold">days</span>
        </div>
        <p className="text-gray-200 font-semibold line-through">
          {item.originalPrice} USDT
        </p>
        <p className="text-green-500 font-semibold text-xl translate-y-[-12.5%]">
          {item.price}{" "}
          <span className="text-green-500 px-2 py-1.5 border border-green-300 rounded-md bg-radial-gradient from-transparent to-green-500/50 pr-6 relative">
            <AngleDown className="w-4 h-4 fill-current text-green-500 absolute right-1.5 top-[50%] -translate-y-[50%]" />
            USDT
          </span>
        </p>
        {item.features.map((feature, index) => (
          <div key={index} className="flex">
            <Tick className="w-[25px] h-[25px] fill-current text-green-300 " />
            <h4 className="text-gray-200">{feature}</h4>
          </div>
        ))}
        <div className="text-gray-200">No KYC Policy</div>
      </div>
    );
  }

  return (
    <div className="h-[500px] w-[300px] border border-indigo-500 rounded-2xl mx-2 flex-none flex flex-col items-center">
      <div className="w-[150px] h-[150px] bg-indigo-500 rounded-full flex flex-col items-center justify-center snap-center">
        <span className="text-black text-5xl font-bold">{item.days}</span>
        <span className="text-black text-5xl font-bold">
          {item.days === 1 ? "day" : "days"}
        </span>
      </div>
      <p className="text-gray-200">{item.price} USDT</p>
    </div>
  );
};

export default function Pricing() {
  useEffect(() => {
    const container = document.querySelector(".overflow-x-scroll");
    const thirtyDaysItem = container.querySelector(".scroll-snap-align-center");

    if (container && thirtyDaysItem) {
      const scrollLeft =
        thirtyDaysItem.offsetLeft -
        container.clientWidth / 2 +
        thirtyDaysItem.offsetWidth / 2;
      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <h2 className="text-gray-100 text-4xl text-center mb-8">
        OUR ACTUAL PRICING:
      </h2>
      <p className="text-gray-100 mb-16 text-center leading-4">
        * prices are shown in cryptocurrency.
        <br /> Contact our support team to learn more{" "}
      </p>
      <div className="w-full max-w-[1200px] mx-auto relative">
        <div className="w-full flex p-2 pl-[calc(50%-150px)] justify-start overflow-x-scroll snap-x snap-mandatory mb-20">
          <div className="text-white absolute w-[150px] top-0 left-0 h-full bg-gradient-to-r from-[black] to-[transparent] z-10" />
          <div className="text-white absolute w-[150px] top-0 right-0 h-full bg-gradient-to-l from-[black] to-[transparent] z-10" />
          {pricingData.map((item, index) => (
            <PricingCard key={index} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
