import { useMutation, useQueryClient } from "@tanstack/react-query";

const postJob = async (jobData) => {
  const response = await fetch("http://localhost:2014/jobs", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jobData),
  });

  if (!response.ok) {
    throw new Error("Failed to create job");
  }

  return response.json();
};

export function useJobMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postJob,
    onSuccess: () => {
      // Invalidate and refetch any queries that might be affected by this mutation
      queryClient.invalidateQueries(["jobs"]);
    },
  });
}
