import React, { useContext, useState } from "react";
import Page from "../../Page/Page";
import { UserContext } from "../../providers/http/UserProvider";
import { Tabs, Datepicker } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import { customTheme } from "./flowbite.config";
import MyTabsSection from "./MyTabsSection";

function formatDate(date) {
  if (!date) return "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export default function ProfilePage() {
  const { user } = useContext(UserContext);
  return (
    <Page>
      <div
        id="main-content-container-1280"
        className="w-full max-w-5xl flex flex-col mx-auto text-white"
      >
        <div className="p-4 pb-8 md:p-8 bg-gray-900 shadow mt-20">
          <div className="grid grid-cols-3">
            <div className="grid grid-cols-3 text-center order-first mt-0">
              <div>
                <p className="font-bold text-grey-300 dark:text-gray-300 text-xl">
                  0
                </p>
                <p className="text-gray-400 dark:text-gray-300">posts</p>
              </div>
              <div>
                <p className="font-bold text-gray-400 dark:text-gray-300 text-xl">
                  0
                </p>
                <p className="text-gray-400 dark:text-gray-300">jobs</p>
              </div>
              <div>
                <p className="font-bold text-gray-400 dark:text-gray-300 text-xl">
                  0
                </p>
                <p className="text-gray-400 dark:text-gray-300">groups</p>
              </div>
            </div>
            <div className="relative">
              <div className="h-20 w-20 md:w-48 md:h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-14 flex items-center justify-center text-indigo-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-[50%] w-[50%]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col mt-0 items-center justify-center">
              <button className="text-white py-2 px-4 uppercase rounded bg-indigo-500 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5 w-full mb-4 max-w-[200px]">
                New Job
              </button>
              <button className="text-white py-2 px-4 uppercase rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5 w-full max-w-[200px]">
                Add Group
              </button>
            </div>
          </div>
          <div className="mt-8 text-center border-b pb-8">
            <h1 className="text-4xl font-medium text-gray-600 dark:text-gray-400">
              {user.firstName} {user.lastName}
            </h1>
            <p className="font-light text-gray-700 dark:text-gray-400 mt-3">
              @{user.userUsername}
            </p>
          </div>
        </div>
        <MyTabsSection />
      </div>
    </Page>
  );
}
