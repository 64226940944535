import React from "react";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as Target } from "./target.svg";
import { ReactComponent as Piggy } from "./piggy.svg";
import { ReactComponent as Secure } from "./secure.svg";
import { ReactComponent as Log } from "./log.svg";
import { ReactComponent as Support } from "./support.svg";

export default function Features() {
  return (
    <>
      {" "}
      <div className="px-3 overflow-x-scroll">
        <div className="max-w-[1200px] mx-auto font-medium relative mt-5 mb-5 scale-y-125">
          {/* <div className="inline-block text-2xl px-4 py-2 bg-rose-900 rounded-3xl font-bold scale-y-90 lg:scale-y-[95%] left-6 lg:left-11 float-left absolute top-0 -mt-4">
            PRO
          </div> */}
          <div className="">
            <div className="mx-4 relative">
              <div className="h-0 float-left text-2xl px-4 py-2 rounded-3xl font-bold mr-5 opacity-0">
                PRO
              </div>
              <p className="text-gray-200 text-2xl md:text-3xl lg:text-4xl lg:leading-9 leading-6">
                MARKETING SOLUTIONS
                <br className="" /> BUILT ON TOP OF TELEGRAM MESSAGING SYSTEM
              </p>
            </div>
          </div>
        </div>
        <div className="w-[200%] sm:w-full sm:max-w-[1250px] mx-auto bg-gradient-to-b bg-gradient-to-b from-gray-100/90 via-gray-100/45 to-transparent rounded-xl md:rounded-3xl pb-40">
          <div className="grid grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4  justify-items-center">
            <div className="rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Clock className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    RAPID
                    <br />
                    START
                  </h3>
                </div>
              </div>
              <div className="">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>
            </div>
            <div className="min-h-[250px] rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Target className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    INHANCED
                    <br />
                    TARGETING
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>
            </div>
            <div className="min-h-[250px] rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Piggy className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    COST
                    <br />
                    EFFECTIVE
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>
            </div>

            <div className="min-h-[250px] rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Secure className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    SECURE &
                    <br />
                    CONFIDENT
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>
            </div>
            <div className="min-h-[250px] rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Log className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    COMPLETE
                    <br />
                    LOGGING
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>{" "}
            </div>
            <div className="min-h-[250px] rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Support className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    PIZDA
                    <br />
                    LOGGING
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>{" "}
            </div>
            <div className="translate-x-0 lg:translate-x-[calc(50%+5px)] xl:translate-x-0 rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Clock className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    RAPID
                    <br />
                    START
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>
            </div>
            <div className="translate-x-0 lg:translate-x-[calc(50%+5px)] xl:translate-x-0 rounded-2xl  bg-gradient-to-b from-gray-100/75 to-gray-300/75 w-full mx-auto ">
              <div className="w-full flex pt-4 pb-1">
                <div className="w-[35%] flex justify-center items-center">
                  <Target className="w-[75px] h-[75px] fill-current text-gray-500" />
                </div>
                <div className="w-[65%] flex justify-start items-center">
                  <h3 className="text-2xl font-bold leading-6">
                    INHANCED
                    <br />
                    TARGETING
                  </h3>
                </div>
              </div>
              <div className=" ">
                <img
                  className="h-2 w-[35%] float-left "
                  src={null}
                  alt={null}
                />
                <p className="leading-5 font-medium px-4 pb-4 text-lg tracking-wide">
                  Architecto sapiente dolorum iusto ad? Hic rem magnam nostrum
                  accusamus nisi libero aliquam earum ad eos. Vero consequuntur
                  blanditiis temporibus enim soluta fugit ad molestias
                  praesentium tempora deserunt aspernatur illo dolores, vitae
                  sit consectetur doloremque nobis commodi delectus alias nisi
                  omnis illum animi natus? Dolores?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
