import { useQuery } from "@tanstack/react-query";

const fetchPosts = async () => {
  const response = await fetch("http://localhost:7117/posts", {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export function usePostsQuery() {
  return useQuery({
    queryKey: ["posts"],
    queryFn: fetchPosts,
    select: (data) => data.data, // This selects the 'data' field from the response
  });
}
