export const makeNestedLists = (myList, itemsPerGroup) => {
  const groupedItems = [];
  let currentGroup = [];

  for (let i = 0; i < myList.length; i++) {
    currentGroup.push(myList[i]);

    if ((i + 1) % itemsPerGroup === 0 || i === myList.length - 1) {
      groupedItems.push(currentGroup);
      currentGroup = [];
    }
  }

  return groupedItems;
};

// const messageDetails = [
//   { title: "Telegram Group:", answer: "Answer" },
//   { title: "Posted At:", answer: "Answer" },
//   { title: "Collected At:", answer: "Answer" },
//   { title: "Tags:", answer: "Answer" },
//   { title: "Z-Authenticated:", answer: "False" },
//   { title: "Claims:", answer: "Not found" },
//   { title: "Post Views:", answer: "123" },
//   { title: "Contact Clicks:", answer: "12" },
// ];

// parseMessageProps(messageDetails);
