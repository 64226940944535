import React from "react";

export default function MessageFound() {
  return (
    <div id="" className="w-full p-4 bg-sky-100">
      <h2 className="font-extrabold mb-3 text-gray-800">Message Found:</h2>
      <div className="w-full flex flex-col items-center">
        <div
          id="message-div"
          className="max-w-[370px] p-3 rounded-lg rounded-bl-none bg-white mr-2"
        >
          <p className="text-gray-800 font-medium">
            This is a description of my awesome post. It's a long-form article
            about something interesting and engaging. We'll explore this topic
            in-depth and provide valuable insights and perspectives.
          </p>
        </div>
      </div>
    </div>
  );
}
