import React, { useContext } from "react";

import Page from "../../Page/Page";
import AvailableBots from "./AvailableBots/AvailableBots";

export default function AllBots() {
  return (
    <Page>
      <div
        id="main-content-container-1280"
        className="w-full max-w-5xl flex flex-col mx-auto pt-[3px]"
      >
        <AvailableBots />
      </div>
    </Page>
  );
}
