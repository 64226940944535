import React, { useCallback } from "react";

export default function useFetchAllTgGroups() {
  const fetchAllTgGroups = useCallback(() => {
    return fetch(`http://localhost:2014/tg-groups`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => data.allTgGroupDocs)
      .catch((error) => {
        console.log(error);
        return [];
      });
  }, []);

  return { fetchAllTgGroups };
}
