import React, { useState, useContext, useEffect } from "react";
import { ReactComponent as Template } from "./images/template.svg";
import { ReactComponent as Dashboard } from "./images/dashboard.svg";
import { ReactComponent as Home } from "./images/home.svg";
import { ReactComponent as Fire } from "./images/fire.svg";
import { ReactComponent as Contract } from "./images/contract.svg";
import { ReactComponent as Hand } from "./images/hand.svg";
import { ReactComponent as Marketing } from "./images/marketing.svg";
import { ReactComponent as Partnership } from "./images/partnership.svg";
import { ReactComponent as AngleDown } from "./images/angle-down.svg";
import { ReactComponent as Tg } from "./images/tg1.svg";
import { MobileMenuContext } from "../../providers/interface/MobileMenuProvider";
import { Link, useNavigate } from "react-router-dom";
import { PopupContext } from "../../providers/interface/PopupProvider";
import { DarkThemeToggle, Flowbite } from "flowbite-react";

// const sideMenu = ["Home", "Categories", "News", "Crypto", "More"];
const menuLinks = [
  { name: "Home", link: "/", icon: null },
  { name: "User Profile", link: "/profile", icon: null },
  // { name: "Tg Supergroups", link: "/tg-groups", icon: null },
];

export default function SideMenu() {
  const { setPopupState } = useContext(PopupContext);
  const navigate = useNavigate();
  function handleClick(link) {
    setMobileMenuOpen("closed");
    navigate(link);
  }

  const { mobileMenuOpen, setMobileMenuOpen } = useContext(MobileMenuContext);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  return (
    <>
      <div className="px-4 h-full overflow-scroll">
        <div
          id="visible-sidemenu-without-padding"
          className={`w-full h-full max-w-[240px] ml-auto mr-0 mx-auto flex flex-col items-center justify-start relative overflow-hidden ${
            mobileMenuOpen === "open" ? "overflow-scroll" : ""
          }`}
        >
          <h3
            id="sidemenu-title"
            className="text-md text-white font-semibold my-10"
          >
            SIDE MENU
          </h3>
          {menuLinks.map((item, index) => (
            <>
              <div
                key={index}
                className="text-white w-full h-8 border-2 border-gray-600 rounded-3xl flex items-center justify-between py-5 px-4 mb-2 hover:bg-gray-700 transition-colors duration-100 cursor-pointer z-10"
                onClick={() => handleClick(item.link)}
              >
                <span className="flex items-center">
                  <h4 className="pl-3 whitespace-nowrap overflow-hidden text-sm">
                    {item.name}
                  </h4>
                </span>
              </div>
            </>
          ))}
        </div>
      </div>
      <div
        id="link-telegram-plate"
        className="outline w-full h-16 bg-gray-200 mt-auto mb-0 flex items-center  px-5 rounded-md  hover:cursor-pointer"
        onClick={() => {
          setMobileMenuOpen("closed");
          setPopupState("block");
        }}
      >
        <div className="w-12 h-12 bg-black rounded-full overflow-hidden mr-4">
          <Tg className="w-[50%] h-[100%] fill-current text-white m-auto" />
        </div>
        <h4 className="w-auto text-center py-8 font-medium text-gray-900 text-sm">
          Link 🔗 Telegram "
        </h4>
      </div>
    </>
  );
}
