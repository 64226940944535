import React, { useCallback } from "react";

export default function useFetchBotById() {
  const fetchBotById = useCallback(() => {
    return fetch(`http://localhost:2014/bot/:botId`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => data.data)
      .catch((error) => {
        console.log(error);
        return [];
      });
  }, []);

  return { fetchBotById };
}
