import { useContext, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { PermitContext } from "./providers/DevModeProvider";

const TestMode = () => {
  const { permit } = useContext(PermitContext);
  return permit ? <Outlet /> : <Navigate to="/otp" />;
};

export default TestMode;
