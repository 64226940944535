import React from "react";

export default function Advertisement({ clChange }) {
  return (
    <div
      id="advertisement"
      className={`w-full h-[200px] bg-gray-700 rounded-2xl ${clChange}`}
    ></div>
  );
}
