import React, { useEffect } from "react";
import { useJobsQuery } from "../fetches/useJobsQuery";

export default function ArchiveJobsTab() {
  const { data, isLoading, error } = useJobsQuery();

  useEffect(() => console.log(data), [data]);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {data[0] ? (
              Object.keys(data[0]).map((objKey, index) => (
                <th key={index} scope="col" className="px-6 py-3">
                  {objKey}
                </th>
              ))
            ) : (
              <p className="py-2 flex justify-center w-full">No Data</p>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((job, index) => (
            <tr
              key={index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              {/* <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              > */}
              {Object.values(job).map((objValue, objIndex) => (
                <td key={objIndex} className="px-6 py-4">
                  {String(objValue) || typeof objValue}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
