import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SectionRefProvider from "./providers/SectionRefProvider";
import MobileMenuProvider from "./providers/interface/MobileMenuProvider";
import PopupProvider from "./providers/interface/PopupProvider";
import ShadowBgProvider from "./providers/interface/ShadowBgProvider";
// import AuthProvider from "./providers/http/AuthProvider";
import ServerErrorProvider from "./providers/http/ServerErrorProvider";
import UserProvider from "./providers/http/UserProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PermitProvider from "./providers/DevModeProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 1 minute
      cacheTime: 300000, // 5 minutes
      retry: 1,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <PermitProvider>
        <SectionRefProvider>
          <MobileMenuProvider>
            <PopupProvider>
              <ShadowBgProvider>
                {/* <AuthProvider> */}
                <UserProvider>
                  <ServerErrorProvider>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </ServerErrorProvider>
                </UserProvider>
                {/* </AuthProvider> */}
              </ShadowBgProvider>
            </PopupProvider>
          </MobileMenuProvider>
        </SectionRefProvider>
      </PermitProvider>
    </StyledEngineProvider>
  </QueryClientProvider>
);
