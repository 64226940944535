import { useEffect, useContext } from "react";
import { UserContext } from "../providers/http/UserProvider";

const useFetchUser = () => {
  const { user, setUser, setUserBool } = useContext(UserContext);
  useEffect(() => {
    fetch(`http://localhost:7117/get-user`, {
      method: "GET",
      credentials: "include",
    })
      .then((anotherResponse) => {
        if (anotherResponse.ok) {
          // Parse the anotherResponse as JSON
          return anotherResponse.json();
        } else {
          console.error("Error making GET request");
          throw new Error("Failed to make GET request");
        }
      })
      .then((anotherData) => {
        setUser({
          firstName: anotherData.user.first_name,
          lastName: anotherData.user.last_name,
          userId: anotherData.user.tg_id,
          userUsername: anotherData.user.tg_username,
          preferedGeo: anotherData.user.prefered_geo,
          preferedSupergroups: anotherData.user.prefered_supergroups,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    console.log("user: ", user);
  }, [user]);
};

export default useFetchUser;
