import React, { useState, useEffect } from "react";
import Page from "../../Page/Page";
import useFetchBotById from "./useFetchBotById";

export default function BotById() {
  const [fetchedBot, setFetchedBot] = useState();
  const { fetchBotById } = useFetchBotById();
  useEffect(() => {
    fetchBotById().then(setFetchedBot);
  }, [fetchBotById]);

  return (
    <Page>
      <div
        id="main-content-container-1280"
        className="w-full max-w-5xl flex flex-col mx-auto pt-[3px]"
      >
        Bot By Id
      </div>
    </Page>
  );
}
