import React, { createContext, useState } from "react";
export const ServerErrorContext = createContext();
const ServerErrorProvider = ({ children }) => {
  const [serverErrorVisible, setServerErrorVisible] = useState(false);
  const [serverError, setServerError] = useState([
    {
      message:
        "Your authantication token is invalid. Please, obtain new one through link-in process",
    },
    // {
    //   message: "Please, update your session token",
    // },
    // {
    //   message: "Something went wrong, please try again",
    // },
  ]);

  const removeError = (errorToRemove) => {
    setServerError((prevErrors) =>
      prevErrors.filter((error) => error !== errorToRemove)
    );
  };

  return (
    <ServerErrorContext.Provider
      value={{
        serverError,
        setServerError,
        removeError,
        serverErrorVisible,
        setServerErrorVisible,
      }}
    >
      {children}
    </ServerErrorContext.Provider>
  );
};

export default ServerErrorProvider;
