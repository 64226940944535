import React, { createContext, useState, useEffect, useContext } from "react";
import { PopupContext } from "./PopupProvider";
import { MobileMenuContext } from "./MobileMenuProvider";

export const ShadowBgContext = createContext();

const ShadowBgProvider = ({ children }) => {
  const { popupState } = useContext(PopupContext);
  const { mobileMenuOpen } = useContext(MobileMenuContext);
  const [shadowBgState, setShadowBgState] = useState("hidden");

  useEffect(() => {
    if (mobileMenuOpen === "open" || popupState === "block") {
      setShadowBgState("opacity-[45%]");
    } else {
      setShadowBgState("hidden");
    }
  }, [mobileMenuOpen, popupState]);

  return (
    <ShadowBgContext.Provider value={{ shadowBgState, setShadowBgState }}>
      {children}
    </ShadowBgContext.Provider>
  );
};

export default ShadowBgProvider;
