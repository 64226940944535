import React from "react";
import Page from "../../Page/Page";
import { ReactComponent as Tg } from "../../tg1.svg";
import { ReactComponent as Strings } from "./strings.svg";

import Reviews from "./Reviews/Reviews";
import GroupCheck from "./GroupCheck/GroupCheck";
import Features from "./Features/Features";
import Pricing from "./Pcicing/Pricing";

const spheres = require("./spheres.svg");

const earth = require("./earth.jpg");

export default function Home() {
  return (
    <Page>
      <div className="w-full min-h-full bg-black relative">
        <div className="pt-4 pb-20 outline max-w-[1100px] mx-auto">
          <div className="mx-auto">
            <h1 className="text-gray-200 text-xl font-medium p-4 md:px-8  lg:px-14">
              ILLUMINATE
              <br />
              YOUR BUSSINESS
              <br />
              PRESENCE
              <br />
              ON TELEGRAM
            </h1>
            <div className="flex">
              {/* <div
                id="sphere-yellow"
                className=" border border-gray-500 w-[250px] h-[250px] bg-orange-600 mx-auto rounded-full mt-auto mb-0  flex-none"
              ></div> */}
              <div
                id="plane-div"
                className="lg:translate-y-[7.5%]  w-[250px] h-[250px] mt-auto mb-0  flex-none"
              >
                <Tg className="w-[50%] h-[100%] fill-current text-white m-auto" />
              </div>
              <span className="w-[650px] translate-x-[12.5%] lg:translate-x-[20%] aspect-[1] mx-auto relative">
                <div
                  id="sphere"
                  className="w-full h-full bg-gradient-to-b from-blue-300 to-indigo-500 rounded-full flex-none overflow-hidden absolute blur-sm"
                />
                <Tg className="w-[100%] h-[100%] fill-current text-black  m-auto -translate-x-[20%] translate-y-[10%] absolute blur-[2px]" />
              </span>
            </div>
          </div>
        </div>
        <p
          className="text-white text-xl leading-7 max-w-[800px] mx-auto text-center mt-20 mb-36 relative flex flex-col items-center p-8 rounded-full 
      text-center"
        >
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem
          molestiae doloribus libero neque rerum natus consequuntur impedit,
          incidunt delectus voluptates aspernatur voluptatum eaque distinctio
          veniam consequatur nostrum optio officiis ullam enim molestias
          reiciendis maxime aperiam? Obcaecati quam expedita hic vero a corrupti
          nemo harum aliquid doloribus deleniti quia quis aperiam explicabo
          dignissimos est quidem porro sapiente inventore illo dolore, officiis
          sit cupiditate! Deleniti harum vel tempora, qui reprehenderit culpa?
          Vitae ducimus, beatae, dignissimos ipsam dolores aliquam quaerat nulla
          blanditiis enim repudiandae alias unde aspernatur sed velit! Saepe
          cupiditate animi est quibusdam alias voluptates! Harum necessitatibus
          eaque, corrupti possimus deserunt omnis ratione enim esse ab
          repudiandae autem. At obcaecati labore alias, asperiores soluta
          aliquam sint optio! Expedita dignissimos quo eius sequi eos molestiae
          obcaecati, neque aliquam sit at eaque beatae soluta ad saepe facilis
          voluptate voluptatem, dolorum autem. Assumenda autem vitae repellat
          mollitia est, velit amet iure sit, maxime et ullam ipsam veniam vel
          qui repellendus sapiente adipisci beatae.
        </p>
        <Features />
        <GroupCheck />
        <Reviews />
        <Pricing />
        <div className="w-full max-w-[1300px] mx-auto flex flex-col items-center justify-center relative">
          {/* <div className="absolute h-[150%] w-[150px] left-0 top-0  bg-gradient-to-r from-[black] to-[transparent] z-10" />
          <div className="absolute h-[150%] w-[150px] right-0 top-0  bg-gradient-to-l from-[black] to-[transparent] z-10" /> */}

          <h3 className="text-white text-center text-3xl mb-12">
            See Our Demo Account
          </h3>
          <button className="text-indigo-50 text-lg p-4 border border-2 border-indigo-500 px-8  bg-radial-gradient from-indigo-500/20 to-indigo-500/60 mb-12">
            Open Demo
          </button>
        </div>
        {/* <Strings className="w-full max-w-[1200px] mx-auto" /> */}
        <div className="h-[400px] flex flex-col items-center justify-center">
          <h3 className="text-pink-50 text-center text-3xl mb-12">
            Subscribe Our Newsletter
          </h3>
          <span className="flex flex-col sm:block">
            <input className="text-white text-lg p-4 border border-2 border-pink-300 px-8  bg-transparent bg-radial-gradient from-pink-300/10 to-pink-300/20 min-w-[400px]" />
            <button className="mt-8 max-w-[200px] mx-auto text-white text-lg p-4 border border-2 border-pink-300 px-8  bg-radial-gradient from-pink-300/20 to-pink-300/60">
              Submit
            </button>
          </span>
        </div>
      </div>
    </Page>
  );
}
