import { useQuery } from "@tanstack/react-query";

const fetchJobs = async () => {
  const response = await fetch("http://localhost:7117/jobs", {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export function useJobsQuery() {
  return useQuery({
    queryKey: ["jobs"],
    queryFn: fetchJobs,
    select: (data) => data.data,
  });
}
