import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { SectionRefContext } from "../../providers/SectionRefProvider";
import { MobileMenuContext } from "../../providers/interface/MobileMenuProvider";
import { ReactComponent as MenuBars } from "./menu-bars.svg";
const Header = () => {
  const { toggleMobileMenuOpen } = useContext(MobileMenuContext);
  const { isHeaderVisible, headerRef } = useContext(SectionRefContext);

  return (
    <header
      className={`fixed top-0 left-0 w-full h-[70px] bg-black bg-opacity-90 text-white h-20 transition-transform duration-300 ease z-50 overflow-hidden backdrop-blur-md ${
        isHeaderVisible ? "translate-y-0" : "-translate-y-full"
      }`}
      ref={headerRef}
    >
      <div className="max-w-6xl w-full h-full mx-auto flex justify-start items-center">
        <div
          class="h-20 w-20 flex items-center justify-center cursor-pointer"
          onClick={toggleMobileMenuOpen}
        >
          <MenuBars className="text-gray-200 h-5 w-5" />{" "}
        </div>
        <h1 className="pl-1 text-2xl font-extrabold min-w-0 montserrat text-gray-200">
          TELEGRAM4.PRO
        </h1>
      </div>
    </header>
  );
};

export default Header;
