import React from "react";
import Page from "../../Page/Page";

const privacyTabs = [
  { title: "Turms of Use" },
  { title: "Privacy Policy" },
  { title: "Cookie Policy" },
  { title: "DMCA Policy" },
];

export default function Policies() {
  return (
    <Page>
      <div className="max-w-4xl mx-auto">
        <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          {privacyTabs.map((item, key) => (
            <li key={key} class="mt-2 mr-1">
              <a
                href="#"
                aria-current="page"
                class="inline-block p-3 text-white font-semibold bg-gray-900 rounded-t-lg active"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
        Terms of Use Welcome to our application (the "App"). By accessing or
        using the App, you agree to be bound by these Terms of Use and our
        Privacy Policy, which is incorporated herein by reference. If you do not
        agree to these Terms of Use or the Privacy Policy, please do not use the
        App. Acceptance of Terms These Terms of Use constitute a legally binding
        agreement between you and the App's owner or operator (the "Company").
        By accessing or using the App, you acknowledge that you have read,
        understood, and agreed to be bound by these Terms of Use and our Privacy
        Policy. Use of the App The App is provided for your personal,
        non-commercial use only. You agree not to modify, copy, distribute,
        transmit, display, perform, reproduce, publish, license, create
        derivative works from, transfer, or sell any information, software,
        products, or services obtained from the App. User Accounts If you create
        an account on the App, you are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account. You agree to promptly notify the Company of
        any unauthorized use of your account. Intellectual Property The App and
        its entire contents, features, and functionality, including but not
        limited to text, graphics, logos, images, and software, are owned by the
        Company and are protected by copyright, trademark, patent, and other
        intellectual property laws. Third-Party Links and Content The App may
        contain links to third-party websites or resources. The Company has no
        control over and assumes no responsibility for the content, privacy
        policies, or practices of any third-party websites or resources.
        Disclaimer of Warranties The App is provided on an "as is" and "as
        available" basis, without warranties of any kind, either express or
        implied, including but not limited to warranties of merchantability,
        fitness for a particular purpose, and non-infringement. Limitation of
        Liability In no event shall the Company or its affiliates be liable for
        any indirect, incidental, special, or consequential damages arising out
        of or related to the use of the App, including but not limited to
        damages for loss of profits, data, or other intangible losses.
        Modifications to the Terms of Use The Company reserves the right to
        modify these Terms of Use at any time without prior notice. Your
        continued use of the App after any such modifications constitutes your
        acceptance of the new Terms of Use. Governing Law These Terms of Use
        shall be governed by and construed in accordance with the laws of
        [Jurisdiction], without giving effect to any principles of conflicts of
        law. Severability If any provision of these Terms of Use is found to be
        invalid or unenforceable, the remaining provisions shall remain in full
        force and effect. Please review these Terms of Use periodically for any
        updates or changes. If you have any questions or concerns regarding
        these Terms of Use, please contact us at [Contact Information].
      </div>
    </Page>
  );
}
