import React, { useState, createContext, useEffect } from "react";

export const FetchAllContext = createContext();

export const FetchAllProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [fetchedCarsItems, setFetchedCarsItems] = useState([]);
  const [fetchedFinanceItems, setFetchedFinanceItems] = useState([]);
  const [fetchedFormsItems, setFetchedFormsItems] = useState([]);
  const [fetchedHealthItems, setFetchedHealthItems] = useState([]);
  const [fetchedHousingItems, setFetchedHousingItems] = useState([]);
  const [fetchedJobsItems, setFetchedJobsItems] = useState([]);

  async function fetchLastDocuments(path, setItems) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${path}`);
      const data = await response.json();
      const dataItems = data.reccentDocs;
      console.log(data);
      if (response.ok) {
        // If the response is successful (status code in the range 200-299)
        setItems(dataItems);
      } else {
        console.log("Server response:", data.message);
      }
    } catch (error) {
      console.error(`Error fetching ${path} items:`, error);
    }
  }

  useEffect(() => {
    const fetchRecentData = async () => {
      // setIsLoading(true);
      // setError(null);
      try {
        await Promise.all([
          fetchLastDocuments("cars", setFetchedCarsItems),
          fetchLastDocuments("finance", setFetchedFinanceItems),
          fetchLastDocuments("forms", setFetchedFormsItems),
          fetchLastDocuments("health", setFetchedHealthItems),
          fetchLastDocuments("housing", setFetchedHousingItems),
          fetchLastDocuments("jobs", setFetchedJobsItems),
        ]);
      } catch (err) {
        console.error("Error fetching recent data:", err);
        // setError(err.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchRecentData();
  }, []);

  useEffect(() => {
    console.log("fetchedHousingItems: ", fetchedHousingItems);
  }, [fetchedHousingItems]);

  return (
    <FetchAllContext.Provider
      value={{
        fetchedCarsItems,
        fetchedFinanceItems,
        fetchedFormsItems,
        fetchedHealthItems,
        fetchedHousingItems,
        fetchedJobsItems,
      }}
    >
      {children}
    </FetchAllContext.Provider>
  );
};
