import React, { useState } from "react";

export default function Dropdown({ isCheckbox, dropdownList, dropdownButton }) {
  const [hiddenState, setHiddenState] = useState("hidden");
  const handleHiddenState = () => {
    if (hiddenState === "") {
      setHiddenState("hidden");
    } else {
      setHiddenState("");
    }
  };
  return (
    <div className="relative w-full max-w-[425px]">
      <button
        id="dropdownDividerButton"
        data-dropdown-toggle="dropdownDivider"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 focus:border-2 max-w-[425px] flex justify-between items-center"
        type="button"
        onClick={handleHiddenState}
      >
        Select Option
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      {/* Dropdown menu */}
      <div
        id="dropdownDivider"
        className={`${hiddenState} z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 w-full mt-2`}
      >
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDividerButton"
        >
          {dropdownList.map((item, index) => (
            <li
              key={index}
              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <div className="flex justify-between">
                <label
                  for="default-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {item.title}
                </label>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="py-2">
          <a
            href="#"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          >
            {dropdownButton.title}
          </a>
        </div>
      </div>
    </div>
  );
}
