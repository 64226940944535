import React, { useContext } from "react";
import Page from "../../Page/Page";
import MediaCarousel from "./e-bocket/MediaCarousel";
import ItemSection from "../AllBotsPage/ItemSection/ItemSection";
import { FetchAllContext } from "../../providers/http/FetchAllProvider";
import { makeNestedLists } from "./e-bocket/logic/makeNestedLists";
import PostHighlights from "./e-bocket/PostHighlights";
import useScreenWidthHook from "./e-bocket/logic/useScreenWidthHook";
import MessageFound from "./e-bocket/MessageFound";
import Advertisement from "../AllBotsPage/Advertisemant/Advertisement";

const messageDetails = [
  { title: "Telegram Group:", answer: "Answer" },
  { title: "Posted At:", answer: "Answer" },
  { title: "Collected At:", answer: "Answer" },
  { title: "Tags:", answer: "Answer" },
  { title: "Z-Authenticated:", answer: "False" },
  { title: "Claims:", answer: "Not found" },
  { title: "Post Views:", answer: "123" },
  { title: "Contact Clicks:", answer: "12" },
];

const actionButtons = [
  { title: "Search Mesage in Telegram", bgColor: "bg-green-100" },
  { title: "Open Contact in Telegram", bgColor: "bg-sky-100" },
  { title: "Save Post to MyRepoSpace", bgColor: "bg-blue-100" },
  { title: "Claim Ownership of This Post", bgColor: "bg-yellow-100" },
  { title: "Accuse Author of the Post", bgColor: "bg-red-100" },
  { title: "Create MyPost", bgColor: "bg-purple-100" },
];

const messageDetailsGrouped = makeNestedLists(messageDetails, 4);
const actionButtonsGrouped = makeNestedLists(actionButtons, 3);

const PostPage = () => {
  const screenWidth = useScreenWidthHook();
  console.log("screenWidth: ", screenWidth);
  const {
    fetchedCarsItems,
    fetchedFinanceItems,
    fetchedFormsItems,
    fetchedHealthItems,
    fetchedHousingItems,
    fetchedJobsItems,
  } = useContext(FetchAllContext);

  return (
    <Page>
      <div className="max-w-4xl mx-auto md:py-8 overflow-hidden flex flex-col">
        {/* <Slider /> */}
        <MediaCarousel />
        <PostHighlights messageDetailsGrouped={messageDetailsGrouped} />
        <MessageFound messageDetails={messageDetails} />
        {/* <h2 className="font-extrabold mb-3 text-gray-800">Message Found:</h2> */}
        <div className="flex justify-center w-full my-10">
          {actionButtonsGrouped.map((group, key) => (
            <div key={key} className="mx-1 flex flex-col items-center">
              {group.map((button, bkey) => (
                <button
                  key={bkey}
                  className={`px-3 py-2 rounded-md border border-gray-700 whitespace-nowrap w-full font-semibold bg-gray-100 mb-1`}
                >
                  {button.title}
                </button>
              ))}
            </div>
          ))}
        </div>
        <Advertisement clChange={"mb-12"} />
        <ItemSection
          title="TOP Rated Posts Today:"
          fetchedItems={fetchedHousingItems}
        />{" "}
        <ItemSection
          title="TOP Rated Posts Today:"
          fetchedItems={fetchedHousingItems}
        />
        <Advertisement clChange={"mb-0 rounded-b-none"} />
      </div>
    </Page>
  );
};

export default PostPage;
