import React, { useState } from "react";
import Dropdown from "../../../Dropdown";
import { Datepicker } from "flowbite-react";
import { datepickerThemeConfig } from "../../../flowbite.config";
import { usePostsQuery } from "../../../PostManager/Tabs/fetches/usePostQuery";
import { useJobMutation } from "../../../../../queries/useJobMutation";
import SelectInput from "../../../SelectInputTest";
import Dropdown2 from "../../../Dropdown2";
import { handlePostRequestSubmit } from "./handlePostRequestSubmit";

const durationOptions = [
  { id: 1, title: "1 day" },
  { id: 2, title: "2 days" },
  { id: 4, title: "4 days" },
  { id: 7, title: "7 days" },
  { id: 14, title: "14 days" },
  { id: 30, title: "30 days" },
  { id: 90, title: "90 days" },
];
const buttonTitle = "Create New Job";
const presetsOptions = [{ title: "Default Option" }];
const presetButton = { title: "Create New Preset" };

export default function NewJobTab() {
  const [jobFormData, setJobFormData] = useState({
    post_ref: null,
    start_date: new Date(),
    duration: 14,
    immediate_start: false,
    prefered_geo: [],
    selected_post: null,
    selected_presets: [],
  });

  const {
    mutate: createJob,
    isLoading: isCreatingJob,
    error: jobCreationError,
  } = useJobMutation();
  const handleSubmit = () => {
    createJob(jobFormData);
  };
  // Load Posts
  const {
    data: fetchedPosts,
    isLoading: isLoadingPosts,
    error: postsError,
  } = usePostsQuery();
  if (isLoadingPosts) return <div>Loading...</div>;
  if (postsError) return <div>Error: {postsError.message}</div>;
  //

  const handlePeriodChange = (id) => {
    setJobFormData((prev) => ({
      ...prev,
      duration: id,
    }));
  };

  const handleDateChange = (date) => {
    setJobFormData((prev) => ({ ...prev, start_date: date }));
  };

  const handlePresetChange = (selected_presets) => {
    setJobFormData((prev) => ({ ...prev, selected_presets }));
  };

  const handlePostChange = (selected_post) => {
    console.log(selected_post);
    setJobFormData((prev) => ({
      ...prev,
      selected_post: selected_post,
      post_ref: selected_post.my_post_id, // Assuming the post has an id field
    }));
  };

  return (
    <div className="w-full flex flex-col items-center p-4">
      <label
        htmlFor="message"
        className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
      >
        Choose Post:
      </label>
      <Dropdown2
        dropdownList={fetchedPosts}
        buttonTitle={buttonTitle}
        onSelect={handlePostChange}
      />

      <label
        htmlFor="message"
        className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
      >
        Choose Period:
      </label>
      <div className="w-full flex justify-center">
        {durationOptions.map((option, index) => (
          <button
            key={index}
            type="button"
            className={`text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:text-white dark:border-gray-600 dark:hover:border-gray-600 dark:focus:ring-gray-700 ${
              option.id === jobFormData.duration
                ? "bg-indigo-500"
                : "hover:bg-gray-100 dark:hover:bg-gray-700"
            }`}
            onClick={() => handlePeriodChange(option.id)}
          >
            {option.title}
          </button>
        ))}
      </div>

      <label
        htmlFor="message"
        className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
      >
        Choose Start Date:
      </label>
      <Datepicker
        theme={datepickerThemeConfig}
        inline
        onSelectedDateChanged={handleDateChange}
        selectedDate={jobFormData.start_date}
      />
      <p>Selected date: {jobFormData.start_date.toDateString()}</p>

      <label
        htmlFor="message"
        className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-left w-full max-w-[700px]"
      >
        Choose Presets (Optional):
      </label>
      <Dropdown
        checkbox={true}
        dropdownList={presetsOptions}
        dropdownButton={presetButton}
        onSelectionChange={handlePresetChange}
      />
      <button
        type="button"
        className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-12 mt-12 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800"
        onClick={() => {
          console.log(jobFormData);
          handlePostRequestSubmit(jobFormData, setJobFormData);
        }}
        disabled={isCreatingJob}
      >
        {isCreatingJob ? "Submitting..." : "Submit Post"}
      </button>
      {jobCreationError && (
        <div className="text-red-500 mt-2">
          Error creating job: {jobCreationError.message}
        </div>
      )}
    </div>
  );
}
