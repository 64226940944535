import React from "react";

const den = require("./den.jpeg");
const bella = require("./bella.jpg");
const nick = require("./nick.jpeg");
const vera = require("./vera.jpg");

export default function Reviews() {
  return (
    <>
      <h2 className="w-full max-w-[1100px] mx-auto text-gray-100 text-4xl font-medium p-4 text-center pt-20 pb-12">
        WE VALUE YOUR LOYALTY
      </h2>
      <div className="relative max-w-[1250px] mx-auto overflow-hidden">
        <div className="hidden 1200:block absolute h-full top-0 w-[75px] bg-gradient-to-r from-black to-transparent z-10" />
        <div className="hidden 1200:block absolute h-full top-0 right-0 w-[75px] bg-gradient-to-l from-black to-transparent z-10" />
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 max-w-[1100px] mx-auto mb-40 overflow-visible">
          <div className="p-3 rounded-2xl max-w-[650px] flex flex-col items-center border border-indigo-500/80 bg-gradient-to-t from-indigo-500/20 to-black relative">
            <div className="hidden lg:block  h-full w-[100px] right-full -translate-x-4 top-0 absolute p-3 rounded-2xl max-w-[650px] flex flex-col items-center  border border-pink-400/80 bg-gradient-to-t from-pink-400/20 to-black" />
            <p className="text-gray-200 text-center mb-2">
              "This Telegram marketing service exceeded my expectations. Their
              targeted campaigns helped boost my channel's subscribers by 40% in
              just two weeks. The team was responsive and provided detailed
              analytics. While pricing was on the higher side, the results
              justified the cost. Highly recommended for serious Telegram
              marketers."
            </p>
            <div className="flex items-center">
              <img
                src={den}
                className="max-w-[65px] rounded-full border border-indigo-500/50 "
              />
              <div className="ml-2 h-full flex flex-col justify-center">
                <h3 className="text-gray-50 leading-5">Denis</h3>
                <h3 className="text-center text-gray-400 leading-5 text-sm">
                  @denchik
                </h3>
              </div>
            </div>
          </div>
          <div className="p-3 rounded-2xl max-w-[650px] flex flex-col items-center border border-pink-400/80 bg-gradient-to-t from-pink-400/20 to-black">
            <p className="text-white text-center mb-2">
              "I had a mixed experience with this service. On the positive side,
              they delivered the promised number of new followers. However,
              engagement rates were lower than expected, and some followers
              seemed inactive. Customer support was average. It's an okay option
              if you're looking for quick growth, but be prepared for
              potentially low-quality leads."
            </p>
            <div className="flex items-center">
              <img
                src={vera}
                className="max-w-[75px] aspect-[1] rounded-full border border-pink-400/50 "
              />
              <div className="ml-2 h-full flex flex-col justify-center">
                <h3 className="text-gray-50 leading-5">Vera</h3>
                <h3 className="text-center text-gray-400 leading-5 text-sm">
                  @venilove
                </h3>
              </div>
            </div>
          </div>

          <div className="p-3 rounded-2xl max-w-[650px] flex flex-col items-center border border-indigo-400/80 bg-gradient-to-t from-indigo-400/20 to-black relative">
            <div className="hidden lg:block h-full w-[100px] left-full translate-x-4 top-0 absolute p-3 rounded-2xl max-w-[650px] flex flex-col items-center  border border-pink-400/80 bg-gradient-to-t from-pink-400/20 to-black" />
            <p className="text-white text-center mb-2">
              Disappointing experience overall. While they promised rapid
              growth, the actual results were mediocre at best. Many of the new
              followers appeared to be bots or inactive accounts. Customer
              service was slow to respond to concerns. I'd suggest looking
              elsewhere for more authentic growth and better value for money."
            </p>
            <div className="flex items-center">
              <img
                src={nick}
                className="max-w-[75px] aspect-[1] rounded-full border border-indigo-400/50 "
              />
              <div className="ml-2 h-full flex flex-col justify-center">
                <h3 className="text-gray-50 leading-5">Nick</h3>
                <h3 className="text-center text-gray-400 leading-5 text-sm">
                  @nickorx
                </h3>
              </div>
            </div>
          </div>
          <div className="p-3 rounded-2xl max-w-[650px] flex flex-col items-center border border-rose-400/80 bg-gradient-to-t from-rose-400/20 to-black relative">
            <div className="hidden lg:block h-full w-[100px] right-full -translate-x-4 top-0 absolute p-3 rounded-2xl max-w-[650px] flex flex-col items-center  border border-indigo-400/80 bg-gradient-to-t from-indigo-400/20 to-black" />
            <p className="text-white text-center mb-2 ">
              "As a small business owner, I found this Telegram marketing
              service to be a game-changer. Their personalized approach and
              creative content strategies helped me connect with my target
              audience effectively. The onboarding process was smooth, and they
              offered valuable insights throughout the campaign. A bit pricey,
              but worth it for the results."
            </p>
            <div className="flex items-center">
              <img
                src={bella}
                className="max-w-[75px] aspect-[1] rounded-full border border-rose-400/50 "
              />
              <div className="ml-2 h-full flex flex-col justify-center">
                <h3 className="text-gray-50 leading-5">Bella</h3>
                <h3 className="text-center text-gray-400 leading-5 text-sm">
                  @sviric
                </h3>
              </div>
            </div>
          </div>
          <div className="p-3 rounded-2xl max-w-[650px] flex flex-col items-center border border-indigo-500/80 bg-gradient-to-t from-indigo-500/20 to-black">
            <p className="text-gray-200 text-center mb-2">
              "This Telegram marketing service exceeded my expectations. Their
              targeted campaigns helped boost my channel's subscribers by 40% in
              just two weeks. The team was responsive and provided detailed
              analytics. While pricing was on the higher side, the results
              justified the cost. Highly recommended for serious Telegram
              marketers."
            </p>
            <div className="flex items-center">
              <img
                src={den}
                className="max-w-[65px] rounded-full border border-indigo-500/50 "
              />
              <div className="ml-2 h-full flex flex-col justify-center">
                <h3 className="text-gray-50 leading-5">Denis</h3>
                <h3 className="text-center text-gray-400 leading-5 text-sm">
                  @denchik
                </h3>
              </div>
            </div>
          </div>
          <div className="p-3 rounded-2xl max-w-[650px] flex flex-col items-center border border-pink-400/80 bg-gradient-to-t from-pink-400/20 to-black relative">
            <div className="hidden lg:block h-full w-[100px] left-full translate-x-4 top-0 absolute p-3 rounded-2xl max-w-[650px] flex flex-col items-center  border border-indigo-400/80 bg-gradient-to-t from-indigo-400/20 to-black" />
            <p className="text-white text-center mb-2">
              "I had a mixed experience with this service. On the positive side,
              they delivered the promised number of new followers. However,
              engagement rates were lower than expected, and some followers
              seemed inactive. Customer support was average. It's an okay option
              if you're looking for quick growth, but be prepared for
              potentially low-quality leads."
            </p>
            <div className="flex items-center">
              <img
                src={vera}
                className="max-w-[75px] aspect-[1] rounded-full border border-pink-400/50 "
              />
              <div className="ml-2 h-full flex flex-col justify-center">
                <h3 className="text-gray-50 leading-5">Vera</h3>
                <h3 className="text-center text-gray-400 leading-5 text-sm">
                  @venilove
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
