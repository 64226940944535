import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function LinkTelegram() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reKey = queryParams.get("re");
    console.log("reKey: ", reKey);

    if (reKey) {
      // Check for existing cookies
      console.log("Existing cookies:", document.cookie);

      fetch(`http://localhost:7117/link-telegram`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reKey }),
        credentials: "include",
      })
        .then((response) => {
          console.log("Response headers:", response.headers);
          console.log("Set-Cookie header:", response.headers.get("Set-Cookie"));
          if (response.ok) {
            return response.json();
          } else {
            console.error("Error making POST request");
            throw new Error("Failed to make POST request");
          }
        })
        .then((data) => {
          console.log("POST response data:", data);

          // Check for cookies after the POST request
          console.log("Cookies after POST:", document.cookie);
          const sessionToken = Cookies.get("session_token");
          console.log("session_token after POST:", sessionToken);

          return fetch(`http://localhost:7117/get-user`, {
            method: "GET",
            credentials: "include",
          });
        })
        .then((anotherResponse) => {
          if (anotherResponse.ok) {
            return anotherResponse.json();
          } else {
            console.error("Error making GET request");
            throw new Error("Failed to make GET request");
          }
        })
        .then((anotherData) => {
          console.log("GET response data:", anotherData);

          // Check for cookies after the GET request
          console.log("Cookies after GET:", document.cookie);
          const sessionToken = Cookies.get("session_token");
          console.log("session_token after GET:", sessionToken);

          navigate("/");
        })
        .catch((error) => {
          console.error("Error:", error);
          navigate("/");
        });
    } else {
      console.log("No query params in a link");
      navigate("/");
    }
  }, [location.search, navigate]);

  return <></>;
}
