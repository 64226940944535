import React, { createContext, useEffect, useState } from "react";
export const UserContext = createContext();
const UserProvider = ({ children }) => {
  const [userBool, setUserBool] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    usedId: "",
    userUsername: "",
    preferedGeo: "",
    preferedSupergroups: "",
  });

  return (
    <UserContext.Provider value={{ user, setUser, userBool, setUserBool }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
