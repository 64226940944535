import React, { useState } from "react";
import JobManager from "./JobManager/JobsManager";
import PostManager from "./PostManager/Postmanager";
import GroupManager from "./GroupManager/GroupManager";
import LogManager from "./LogManager/LogManager";

const tabsList = [
  { id: 0, title: "Job Manager", component: JobManager },
  { id: 1, title: "Post Manager", component: PostManager },
  { id: 2, title: "Group Manager", component: GroupManager },
  { id: 3, title: "Log Manager", component: LogManager },
];

export default function MyTabsSection() {
  const [firstLevelTab, setFirstLevelTab] = useState(0);

  const handleFirstTabClick = (index) => {
    setFirstLevelTab(index);
  };

  const renderComponent = () => {
    const ActiveComponent = tabsList[firstLevelTab].component;
    return <ActiveComponent />;
  };

  return (
    <div id="tabs-section" className="min-h-[500px]">
      <div
        id="tabs-line"
        className="w-full flex justify-between border-b border-gray-600"
      >
        {tabsList.map((tab, index) => (
          <div
            key={index}
            onClick={() => handleFirstTabClick(index)}
            className={`flex items-center justify-center rounded-t-lg h-14 px-4 text-sm font-medium first:ml-0 min-w-max border-b-2 border-transparent text-gray-500 hover:border-indigo-300 dark:text-gray-400 cursor-pointer ${
              firstLevelTab == index
                ? "border-b-2 border-indigo-600 text-indigo-600 dark:border-indigo-500 dark:text-indigo-400"
                : ""
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {tabsList.map((tab, index) =>
        index === firstLevelTab ? <tab.component key={index} /> : null
      )}
    </div>
  );
}
