import React, { createContext, useState } from "react";
export const PermitContext = createContext();
const PermitProvider = ({ children }) => {
  const [permit, setPermit] = useState(false);

  return (
    <PermitContext.Provider value={{ permit, setPermit }}>
      {children}
    </PermitContext.Provider>
  );
};

export default PermitProvider;
