import React from "react";
import { NewItemSuspence } from "../../../components/Item/ItemSuspence";

import LoadMore from "../../../components/Item/LoadMore";
import { ItemNoImage } from "../../../components/Item/ItemNoImage";
import NewItem from "../../../components/Item/NewItem";
import ThreeDotsButton from "./ThreeDotsButton";

const ItemSection = ({ title, fetchedItems }) => {
  // console.log("fetchedItems: ", fetchedItems);
  const divs = Array.from({ length: 10 }, (item, index) => index + 1);

  return (
    <div id="item-section" className="mb-14">
      <h4 className="mb-2 pl-6 text-2xl font-black">
        {title}
        {/* <ThreeDotsButton /> */}
      </h4>
      <div className="overflow-x-scroll">
        <div
          id="item-section-row"
          className="whitespace-nowrap pl-3 xl:pl-0 py-4 inline-flex"
        >
          <NewItem />
          <NewItem />
          <NewItem />
          {/* <Item item={null} key={0} />
          <ItemNoImage item={null} key={0} /> */}

          {fetchedItems &&
            fetchedItems.length > 0 &&
            fetchedItems.map((item, index) => (
              <NewItem item={item} key={index} />
            ))}
          {fetchedItems.length === 0 &&
            divs.map((_, index) => <NewItemSuspence />)}
          <LoadMore />
        </div>
      </div>
    </div>
  );
};

export default ItemSection;
