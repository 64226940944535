import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import useFetchAvailableBots from "./useFetchAvailableBots";

export default function AvailableBots() {
  const [availableBots, setAvailableBots] = useState([]);
  const { fetchAvailableBots } = useFetchAvailableBots();
  useEffect(() => {
    fetchAvailableBots().then(setAvailableBots);
  }, [fetchAvailableBots]);
  // const updateData = () => {
  //   setAvailableBots([]);
  //   fetchAvailableBots()
  //     .then(setAvailableBots)
  //     .then(console.log(availableBots));
  // };

  useEffect(() => {
    console.log(availableBots);
  }, [availableBots]);

  return (
    <>
      <div
        id="top-bar"
        className="w-full outline p-4 flex items-center justify-between bg-gray-900 rounded-xl"
      >
        <h2 className="text-xl text-white font-semibold">Available bots:</h2>
        <div
          onClick={null}
          className="w-8 h-8 bg-gray-100 rounded-md flex items-center justify-center cursor-pointer"
        >
          <FontAwesomeIcon
            icon={faArrowRotateRight}
            className="w-[75%] h-[75%] pr-[1px]"
          />
        </div>
      </div>
      <div className="flex flex-wrap py-4 mx-auto justify-center">
        {availableBots.length > 0 &&
          availableBots.map((bot, index) => (
            <div
              key={index}
              className="mx-auto sm:mx-0 max-w-[420px] w-full sm:w-[50%] shrink-0 p-1"
            >
              <div className="w-full h-full border border-2 border-gray-900 px-2 py-1 bg-gray-100 rounded-xl">
                {Object.entries(bot).map(([key, field]) => (
                  <div key={key} className="">
                    <h4 className="font-semibold bg-indigo-200 px-4 py-0.5 rounded-full">
                      {field.title}
                    </h4>{" "}
                    <p className="text-lg px-2 py-1.5">
                      {Array.isArray(field.value) ? (
                        field.value.length
                      ) : (
                        <span
                          className={`${
                            key == "client_name"
                              ? "ml-4 font-semibold text-xl underline border-purple-300 rounded-full text-blue-700 cursor-pointer"
                              : ""
                          }`}
                        >
                          {key == "client_name" && "@ "}
                          {field.value}
                        </span>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}{" "}
      </div>
    </>
  );
}
