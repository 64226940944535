import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PostById from "./pages/PostById";
import Post from "./pages/PostPage/PostPage";
import Profile from "./pages/ProfilePage/ProfilePage";
// import Profile2 from "./pages/ProfilePage/Profile2";
import LinkTelegram from "./pages/LinkTelegram/LinkTelegram";
import PrivateRoutes from "./PrivateRoutes";
import TestMode from "./TestMode";
import OTPInput from "./Otp";
import Mui from "./pages/Mui";
import Policies from "./pages/Policies/Policies";
import useEStart from "./hooks/useEStart";
import useFetchUser from "./hooks/useFetchUser";
import BotById from "./pages/BotById/BotById";
import AllBotsPage from "./pages/AllBotsPage/AllBotsPage";
import TgGroupsPage from "./pages/TgGroupsPage/TgGroupsPage";
import Page404 from "./pages/Page404/Page404";
import Home from "./pages/Home/Home";

function App() {
  useEStart();
  useFetchUser();

  return (
    <>
      <Router>
        <Routes>
          <Route element={<TestMode />}>
            <Route path="/" element={<Home />} />
            <Route path="/all-bots" element={<AllBotsPage />} />
            <Route path="/link-telegram" element={<LinkTelegram />} />
            <Route path="/profile" element={<Profile />} />
            {/* <Route path="/profile2" element={<Profile2 />} /> */}
            {/* </Route> */}
            <Route path="/post" element={<Post />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/post/:postId" element={<PostById />} />
            <Route path="/bot" element={<BotById />}>
              <Route path="/bot/:botId" element={<BotById />} />
            </Route>
            <Route path="/tg-groups" element={<TgGroupsPage />} />
            <Route path="/*" element={<Page404 />} />
          </Route>
          <Route path="/otp" element={<OTPInput />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
