import React, { useEffect, useState } from "react";

export default function AddNewGroupTab() {
  return (
    <div id="tab-container" className="w-full flex flex-col items-center">
      <label
        htmlFor="message"
        className="block mb-2 text-md font-semibold pt-6 pb-4 text-gray-900 dark:text-white text-center w-full max-w-[700px]"
      >
        Search Your Group in Our Groups Database:
      </label>
      <input
        type="text"
        id="post_title"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 max-w-[425px] mb-8"
        placeholder="@your_favorite_group"
        required
      />
      <div className="relative">
        <button
          type="button"
          className="text-white bg-indigo-400 dark:bg-indigo-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          disabled
        >
          Disabled button
        </button>
      </div>
    </div>
  );
}
