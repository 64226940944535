import React, { useState, useRef, useContext } from "react";
import Page from "./Page/Page";
import { useNavigate } from "react-router-dom";
import { PermitContext } from "./providers/DevModeProvider";

const OTPInput = () => {
  const { setPermit } = useContext(PermitContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value !== "") {
      // Move focus to the next input field
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      } else {
        // Check if all fields are filled
        if (newOtp.every((digit) => digit !== "")) {
          handleComplete(newOtp.join(""));
        }
      }
    } else {
      // Move focus to the previous input field
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleComplete = (completeOtp) => {
    if (completeOtp == process.env.REACT_APP_CODE) {
      setPermit(true);
      navigate("/");
    } else {
      setOtp(["", "", "", "", "", ""]);
      inputRefs.current[0].focus();
    }
  };

  return (
    <Page>
      <div className="mx-auto mt-[200px]">
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={value}
            onChange={(e) => handleChange(e, index)}
            ref={(el) => (inputRefs.current[index] = el)}
            className="bg-transparent border border-2 border-sky-600 w-16 h-16 text-white mx-auto text-center mr-1 focus:ring-2 focus:ring-indigo-500"
          />
        ))}
      </div>
    </Page>
  );
};

export default OTPInput;
