import React, { useState } from "react";
import { Skeleton } from "@mui/material";
import { ReactComponent as AngledBracket } from "./angled-bracket.svg";
import { ReactComponent as Berry } from "./berry.svg";
//  fill-current

export function NewItemSuspence({ div, index }) {
  return (
    <div key={index} className="h-[280px] w-[200px] inline-block pr-3 lg:pr-4">
      <div className="h-full animate-c-shadow rounded-xl overflow-hidden flex flex-col">
        <div className="w-full h-hull flex ilems-center justify-center  flex-1">
          <div className="w-[56.25%] my-auto pb-3 animate-pulse">
            <Berry className="fill-current text-gray-400 pr-2.5 animate-rotate-y" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ItemSuspence({ div, index }) {
  return (
    <div key={index} className="h-[280px] w-[200px] inline-block  pr-3 lg:pr-4">
      <div className="h-full border border-2  rounded-xl overflow-hidden flex flex-col hover:scale-[102.5%] scale-transition duration-200">
        <Skeleton variant="rectangular" width={210} height={118} />
        <Skeleton
          className="mt-[15px]"
          variant="rectangular"
          width={210}
          height={25}
        />
        <Skeleton
          className="mt-[10px]"
          variant="rectangular"
          width={210}
          height={10}
        />
        <Skeleton
          className="mt-[10px]"
          variant="rectangular"
          width={210}
          height={10}
        />
      </div>
    </div>
  );
}
