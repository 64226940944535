import React, { useContext, useState } from "react";
// import { SideMenuContext } from "../../providers/SideMenuProvider";
import SideMenu from "../SideMenu/SideMenu";
import { MobileMenuContext } from "../../providers/interface/MobileMenuProvider";
import ShadowBackground from "../ShadowBackground/ShadowBackground";

export default function MobileMenu() {
  const { mobileMenuOpen } = useContext(MobileMenuContext);

  // const { sideMenuState, setSideMenuState, toggleSideMenuState } =
  //   useContext(SideMenuContext);
  return (
    <div id="" className={`2xl:hidden`}>
      <ShadowBackground mobileMenuOpen={mobileMenuOpen} />
      <div
        id="mobilemenu-visible-area"
        className={`fixed h-[100vh] w-[250px] left-0  bg-opacity-[90%] backdrop-blur-md bg-black top-0 -translate-x-[290px] shadow-custom-45 shadow-custom-15 overflow-hidden flex flex-col z-[60] ${
          mobileMenuOpen === "open" ? "translate-x-0 animate-slide-in" : ""
        } ${
          mobileMenuOpen === "closed"
            ? "-translate-x-[290px] animate-slide-out"
            : ""
        }`}
      >
        <SideMenu />
      </div>
    </div>
  );
}
