import React, { createContext, useState } from "react";
export const PopupContext = createContext();

const PopupProvider = ({ children }) => {
  const [popupState, setPopupState] = useState("hidden");

  const togglePopupState = () => {
    if (popupState === "hidden") {
      setPopupState("block");
    } else {
      setPopupState("hidden");
    }
  };
  return (
    <PopupContext.Provider
      value={{ popupState, setPopupState, togglePopupState }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export default PopupProvider;
