import React, { useContext, useEffect } from "react";
import { ShadowBgContext } from "../../providers/interface/ShadowBgProvider";
import { MobileMenuContext } from "../../providers/interface/MobileMenuProvider";

export default function ShadowBackground() {
  const { setMobileMenuOpen } = useContext(MobileMenuContext);
  const { shadowBgState } = useContext(ShadowBgContext);

  return (
    <div
      id="mobilemenu-background"
      className={`fixed h-[100vh] w-[100vw] bg-black opacity-0 left-0 top-0 transition-opacity duration-400 z-10 ${shadowBgState}`}
      onClick={() => setMobileMenuOpen("closed")}
    />
  );
}
