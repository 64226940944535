import React, { useContext } from "react";
import ShadowBackground from "../ShadowBackground/ShadowBackground";
import { ReactComponent as ChatBot } from "./chatbot.svg";
import { ReactComponent as CloseButton } from "../0/close.svg";
import { PopupContext } from "../../providers/interface/PopupProvider";

export default function Popup() {
  const { popupState, setPopupState } = useContext(PopupContext);

  return (
    <>
      <div
        className={`fixed z-[11] left-[50%] top-[50%]  -translate-x-1/2 -translate-y-1/2 p-4 w-full text-white ${popupState} z-100`}
      >
        <div className="mx-auto w-full max-w-[900px] h-[500px] flex flex-col items-center justify-evenly rounded-xl border border-4 border-sky-500 backdrop-blur-sm bg-black bg-opacity-70 text-center pt-6 pb-10 relative">
          <div
            className="absolute w-[40px] h-[40px] right-0 top-0  -translate-y-[100%] bg-gray-200 rounded-lg"
            onClick={() => {
              setPopupState("hidden");
            }}
          >
            <CloseButton className="w-full h-full" />
          </div>
          <h2 className="font-medium text-lg">
            Personalize Your Experience
            <br />
            By Linking This Session With Telegram
            <br />
            Through Our Official Telegram Chatbot:
          </h2>
          <ChatBot className="text-gray-600 w-[200px] h-[200px]" />
          <a
            href="https://t.me/zemlyanikanet_bot"
            rel="noreferrer"
            target="_blank"
            className="text-sky-50 text-lg p-4 border border-2 border-sky-500 px-8  bg-radial-gradient from-sky-500/20 to-sky-500/60 mb-12"
          >
            {/* <button className="text-indigo-50 text-lg p-4 border border-2 border-indigo-500 px-8  bg-radial-gradient from-indigo-500/20 to-indigo-500/60 mb-12">
            Open Demo
          </button> */}
            Open @REG_LINK_BOT
          </a>
        </div>
      </div>
    </>
  );
}
