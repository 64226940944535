import React, { useState } from "react";

export default function Dropdown2({ dropdownList, buttonTitle, onSelect }) {
  const [hiddenState, setHiddenState] = useState("hidden");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleHiddenState = () => {
    setHiddenState(hiddenState === "" ? "hidden" : "");
  };

  const handleSelect = (item) => {
    setSelectedOption(item);
    setHiddenState("hidden");
    if (onSelect) onSelect(item); // Call the onSelect callback
  };

  return (
    <div className="relative w-full max-w-[425px]">
      <button
        id="dropdownDividerButton"
        data-dropdown-toggle="dropdownDivider"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 focus:border-2 max-w-[425px] flex justify-between items-center"
        type="button"
        onClick={() => {
          handleHiddenState();
        }}
      >
        {selectedOption ? selectedOption.title : "Select Option"}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
        id="dropdownDivider"
        className={`${hiddenState} z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 w-full mt-2`}
      >
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDividerButton"
        >
          {dropdownList.map((item, index) => (
            <li
              key={index}
              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              onClick={() => {
                handleSelect(item);
              }}
            >
              {item.title}
            </li>
          ))}
        </ul>
        {buttonTitle && (
          <div
            href="#"
            className="block px-4 py-2 text-sm text-gray-700
            hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200
            dark:hover:text-white"
          >
            {buttonTitle}
          </div>
        )}
      </div>
    </div>
  );
}
